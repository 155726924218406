
//export const WEB_URL_UPLOAD = "http://localhost/Audio books/api/";
//export const WEB_URL = "http://192.168.0.128/Audio books/api/";

export const WEB_URL_UPLOAD = "https://audiobi.com/api/";
export const WEB_URL = "https://audiobi.com/api/";
export const WEB_URL_SEO = "https://audiobi.com/";


 export const CAPTCHA_SITE_KEY = "6LcTPw8dAAAAAD8vZ-6wlGg-9ZV9z3_9cysPqGq6";



 // Paypal Link
export const PAYPAL_URL_AUDIO = "https://audiobi.com/api/paypal/audiorequest.php";
export const PAYPAL_URL_AUDIO_UPGRADE = "https://audiobi.com/api/paypal/audiorequestupgrade.php";



// New paypal php code
//https://github.com/paypal/PayPal-PHP-SDK/releases
// https://www.evoluted.net/thinktank/web-development/using-paypals-rest-api-with-php-and-mysql

//var CLIENT ='AUJoKVGO3q1WA1tGgAKRdY6qx0qQNIQ6vl6D3k7y64T4qh5WozIQ7V3dl3iusw5BwXYg_T5FzLCRguP8';
//var SECRET ='EOw8LNwDhM7esrQ3nHfzKc7xiWnJc83Eawln4YLfUgivfx1LGzu9Mj0F5wlarilXDqdK9Q5aHVo-VGjJ';

//another source code
//https://www.sanwebe.com/2014/09/paypal-rest-api-payment-system

// paypal json requset sample
//https://developer.paypal.com/docs/integration/paypal-plus/mexico-brazil/create-a-payment-request/
//https://daniela-zimmermann-immobilien.at/wp-content/plugins/estatik/vendor/paypal/rest-api-sdk-php/sample/payments/UpdatePayment.php

 //.com/?gl=us
 // ftp password: @$2z/]Q+8rH
 //bioudioks

 //Welcome to Audiobi, Your payment for audio book promotion processed successfully
 // date
 // invoice id