import React, { Suspense, lazy } from 'react';
import {BrowserRouter as Router,Redirect,Switch,Route} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../App.css';
//import Sticky from './Sticky';
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
const Contactus = lazy(() => import('./Contactus'));
const BooksPreview = lazy(() => import('./BooksPreview'));
const PremiumBooks = lazy(() => import('./PremiumBooks'));
const FeaturedBooks = lazy(() => import('./FeaturedBooks'));
const FreeBooks = lazy(() => import('./FreeBooks'));
const AudioSample = lazy(() => import('./AudioSample'));
const Authors = lazy(() => import('./Authors'));
const AuthorPage = lazy(() => import('./AuthorPage'));
const Categeorylist = lazy(() => import('./Categeorylist'));
const ForgetPassword = lazy(() => import('./login/ForgetPassword'));
const ChangePassword = lazy(() => import('./login/ChangePassword'));
const LoginSection = lazy(() => import('./login/LoginSection'));
const Logout = lazy(() => import('./login/Logout'));
const AccountSection = lazy(() => import('./account/AccountSection'));
const EditProfile = lazy(() => import('./account/EditProfile'));
const AdminSection = lazy(() => import('./account/AdminSection'));
const AddBook = lazy(() => import('./account/AddBook'));
const EditBookSection = lazy(() => import('./account/EditBookSection'));
const EditBook = lazy(() => import('./account/EditBook'));
const PaymentPage = lazy(() => import('./account/payment/PaymentPage'));
const StripeSuccessaudio = lazy(() => import('./account/payment/StripeSuccessaudio'));
const StripeCancelaudio = lazy(() => import('./account/payment/StripeCancelaudio'));
const StripeCancelupgradebooks = lazy(() => import('./account/payment/StripeCancelupgradebooks'));
const PaypalSuccessaudio = lazy(() => import('./account/payment/PaypalSuccessaudio'));
const PaypalCancelaudio = lazy(() => import('./account/payment/PaypalCancelaudio'));
const AudioCancelled = lazy(() => import('./account/payment/AudioCancelled'));
const PaymentPageUpgrade = lazy(() => import('./account/payment/PaymentPageUpgrade'));
const AudioRecepits = lazy(() => import('./account/payment/AudioRecepits'));
const NotFound = lazy(() => import("./NotFound"));
const Demo = lazy(() => import("./Demo"));

function Dashboard() {
  return (<>   
    <Router basename="/">
      <Suspense fallback={<div className="divLoaderwww">
      <svg id="wave" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 38.05"><title>Audio Wave</title> <path id="Line_1" data-name="Line 1" d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"/><path id="Line_2" data-name="Line 2" d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"/><path id="Line_3" data-name="Line 3" d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"/><path id="Line_4" data-name="Line 4" d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"/><path id="Line_5" data-name="Line 5" d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"/><path id="Line_6" data-name="Line 6" d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z"/><path id="Line_7" data-name="Line 7" d="M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z"/>  <path id="Line_8" data-name="Line 8" d="M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z"/>  <path id="Line_9" data-name="Line 9" d="M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z"/></svg>
      </div>}>
        <ToastContainer />
        <Switch>
         <Route exact path="/" component={Home}/>
         <Route exact path="/LoginSection" component={LoginSection}/>
         <Route exact path="/ForgetPassword" component={ForgetPassword}/>
         <Route exact path="/PremiumBooks" component={PremiumBooks}/>
         <Route exact path="/FeaturedBooks" component={FeaturedBooks}/>
         <Route exact path="/FreeBooks" component={FreeBooks}/>
         <Route exact path="/AudioSample" component={AudioSample}/>
         <Route exact path="/Authors" component={Authors}/>
         <Route exact path="/ChangePassword/:passkey" component={ChangePassword}/>
         <Route exact path="/Categeorylist/:categorytitle" component={Categeorylist}/>
         <Route exact path="/AccountSection" component={AccountSection}/>
         <Route exact path="/EditProfile" component={EditProfile}/>
         <Route exact path="/About" component={About}/>
         <Route exact path="/AddBook" component={AddBook}/>
         <Route exact path="/Logout" component={Logout}/>
         <Route exact path="/EditBookSection" component={EditBookSection}/>
         <Route exact path="/EditBook/:Addpreview" component={EditBook}/>
         <Route exact path="/AuthorPage/:Addpreview" component={AuthorPage}/>
         <Route exact path="/BooksPreview/:Addpreview/:Addtitle" component={BooksPreview}/>
         <Route exact path="/PaymentPage/:Addpreview" component={PaymentPage}/>
         <Route exact path="/PaymentPageUpgrade/:Addpreview" component={PaymentPageUpgrade}/>
         <Route exact path="/StripeSuccessaudio/:Addpreview" component={StripeSuccessaudio}/>
         <Route exact path="/StripeCancelaudio/:Addpreview" component={StripeCancelaudio}/>
         <Route exact path="/StripeCancelupgradebooks/:Addpreview" component={StripeCancelupgradebooks}/>
         <Route exact path="/PaypalSuccessaudio/:Addpreview" component={PaypalSuccessaudio}/>
         <Route exact path="/PaypalCancelaudio/:Addpreview" component={PaypalCancelaudio}/>
         <Route exact path="/AudioCancelled" component={AudioCancelled}/>
         <Route exact path="/AudioRecepits/:Addpreview" component={AudioRecepits}/>
         <Route exact path="/AdminSection" component={AdminSection}/>
         <Route exact path="/Contactus" component={Contactus}/>
          

         <Route exact path="/404" component={NotFound} />
         <Route exact path="/Demo" component={Demo} />
         <Redirect to="/404" />

        </Switch>
      </Suspense>
      {/* <Sticky /> */}
    </Router>
    
    </>
  );
}
export default Dashboard;
